import React, { useLayoutEffect } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Location } from '@reach/router'

const Confirmation = (props) => {
  return (
    <Location>
      {({ location, navigate }) => {
        let emailFromURL = 'info@lvsiron.com'

        var currentTime = new Date()
        currentTime.setDate(currentTime.getDate() + parseInt(2))

        return (
          <Layout pageProps={props}>
            {' '}
            <Helmet title={'Confirmation'}>
              <meta name="robots" />
            </Helmet>
          </Layout>
        )
      }}
    </Location>
  )
}

export default Confirmation
